import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import addMonths from 'date-fns/addMonths';
import isSameMonth from 'date-fns/isSameMonth';
import subMonths from 'date-fns/subMonths';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import getLocalizedData from '@utils/localize';
import useSearchSortFilter from '@utils/searchSortFilter';
import { TranslationContext } from '@utils/useTranslations';
import CalendarHeader from '@components/CalendarHeader';
import Container from '@components/Container';
import EmptyState from '@components/EmptyState';
import EventCalendar from '@components/EventCalendar';
import EventSummary from '@components/EventSummary';
import Footer from '@components/Footer';
import Navigation from '@components/Navigation';
import SearchSortFilter from '@components/SearchSortFilter';
import SEO from '@components/SEO';
import Space from '@components/Space';

function CalendarPage({ banner, navItems }) {
  const rawData = useStaticQuery(graphql`
    query CalendarQuery {
      allSanityEvent {
        edges {
          node {
            id
            allDay
            category {
              title {
                en
                vi
                zh_Hans
                _type
              }
            }
            endTime
            eventbriteLink
            excerpt {
              en
              vi
              zh_Hans
              _type
            }
            locationLabel {
              en
              vi
              zh_Hans
              _type
            }
            mapLink
            slug {
              current
            }
            startTime
            tags
            title {
              en
              vi
              zh_Hans
              _type
            }
          }
        }
      }
    }
  `);

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [layout, setLayout] = useState('list');

  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const intl = useIntl();
  const data = getLocalizedData(rawData, intl.locale);
  const events = data.allSanityEvent.edges.map(({ node }) => ({
    ...node,
    title: node.title,
    startTime: new Date(node.startTime),
    endTime: new Date(node.endTime),
  }));

  const translationContext = {
    vi: !events.find((e) => !get(e, 'excerpt.vi') || !get(e, 'title.vi')),
    zh_Hans: !events.find(
      (e) => !get(e, 'excerpt.zh_Hans') || !get(e, 'title.zh_Hans'),
    ),
  };

  const categories = [
    ...new Set(events.map((e) => get(e, 'category.title', false))),
  ]
    .filter(Boolean)
    .sort();

  const { controller, items } = useSearchSortFilter(events, {
    initialFilter: {
      'category.title': [],
    },
    searchFields: ['category', 'excerpt', 'tags', 'title'],
  });

  const handleBack = () => {
    controller.search('');
  };

  const renderEvents = () => {
    if (get(controller, 'state.search')) {
      if (items.length) {
        return items.map((event, idx) => <EventSummary key={idx} {...event} />);
      }

      return <EmptyState />;
    }

    if (layout === 'calendar') {
      return <EventCalendar date={currentMonth} events={items} />;
    }

    const monthEvents = items.filter((event) =>
      isSameMonth(event.startTime, currentMonth),
    );

    if (monthEvents.length) {
      return monthEvents.map((event, idx) => (
        <EventSummary key={idx} {...event} />
      ));
    }

    return <EmptyState />;
  };

  const searchProps = {};
  if (categories.length) {
    searchProps.filters = [
      {
        key: 'category.title',
        title: intl.formatMessage({ id: 'category' }),
        items: categories.map((category) => ({
          name: category,
          value: category,
        })),
      },
    ];
  }

  return (
    <>
      <TranslationContext.Provider value={translationContext}>
        <Navigation banner={banner} navItems={navItems} />
        <main>
          <SEO title="Calendar" />
          <Container centered>
            <CalendarHeader
              date={currentMonth}
              layout={layout}
              onBack={handleBack}
              onChangeLayout={setLayout}
              onPrevMonth={handlePrevMonth}
              onNextMonth={handleNextMonth}
              showingResults={get(controller, 'state.search')}
            />
          </Container>
          <SearchSortFilter
            controller={controller}
            search={{ title: intl.formatMessage({ id: 'calendar.search.title' }) }}
            {...searchProps}
          />
          <Container centered>{renderEvents()}</Container>
        </main>
        {/*<Space sizes={['xsmall', 'small']} />*/}
        <Footer />
      </TranslationContext.Provider>
    </>
  );
}

export default CalendarPage;
